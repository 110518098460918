import React from 'react';

import {useNavigation} from '@react-navigation/native';
import {StyleSheet} from 'react-native';

import {
  IconView,
  LocalizedText,
  LocalizedTextType,
  IconType,
  Typography,
  Pressable,
  XStack,
  View,
  AppletWidgetComponent,
} from '@medad-sep/core';

export interface PageItemProps {
  id: string;
  index?: number;
  title?: LocalizedTextType;
  icon: IconType;
  suffix?: IconType;
  Wrapper?: AppletWidgetComponent;
}

export const PageItem = ({id, title, icon, suffix, Wrapper}: PageItemProps) => {
  const navigation = useNavigation();

  const item = (
    <XStack
      borderTopWidth={1}
      paddingVertical="$4"
      borderTopColor="$border"
      paddingHorizontal="$3.5"
      alignItems="center"
      justifyContent="flex-start"
      flex={1}
      width="100%">
      <View
        padding="$2.5"
        borderRadius="$rounded-lg"
        alignItems="center"
        justifyContent="center"
        backgroundColor="$bg-brand-primary">
        <IconView key="icon" icon={icon} defaultIcon="File01Icon" size={24} />
      </View>
      <Typography flex={1} marginHorizontal="$4" key="text" fontWeight="700">
        <LocalizedText value={title} />
      </Typography>
      {suffix && <IconView key="suffix" icon={suffix} size={24} />}
    </XStack>
  );

  if (Wrapper) {
    return Wrapper({children: item});
  }

  return (
    <Pressable flexDirection="row" flex={1} onPress={() => navigation.navigate(id as never)} $esm={{height: '$6'}}>
      {item}
    </Pressable>
  );
};

export const styles = StyleSheet.create({
  elevation: {
    elevation: 4,
    backgroundColor: 'white',
    shadowColor: 'gray',
    shadowOpacity: 1,
  },
});

import { CurrentUserStorageKey, StoreStorageKey, serializeUser, BaseStore } from "@featbit/react-client-sdk";
import AsyncStorage from './ConditionalAsyncStorage';
export default class ReactNativeStore extends BaseStore {
    constructor(options) {
        super();
        this.logger = options.logger;
    }
    /* eslint-disable class-methods-use-this */
    close() {
        // This is a no-op.
    }
    get description() {
        return 'local-storage-store';
    }
    async saveUser() {
        try {
            await this.set(CurrentUserStorageKey, serializeUser(this._user));
        }
        catch (error) {
            this.logger.debug(`Error saving user`);
        }
    }
    async set(key, value) {
        try {
            await AsyncStorage.setItem(key, value);
        }
        catch (error) {
            this.logger.debug(`Error saving AsyncStorage key: ${key}, value: ${value}, error: ${error}`);
        }
    }
    async dumpStoreToStorage() {
        const storageKey = `${StoreStorageKey}-${this._user.keyId}`;
        await this.set(storageKey, JSON.stringify(this.store));
    }
    async loadStoreFromStorage() {
        const storageKey = `${StoreStorageKey}-${this._user.keyId}`;
        const dataStoreStr = await AsyncStorage.getItem(storageKey);
        let store = null;
        try {
            if (dataStoreStr && dataStoreStr.trim().length > 0) {
                store = JSON.parse(dataStoreStr);
            }
        }
        catch (err) {
            this.logger.error(`error while loading local data store: ${storageKey}`, err);
        }
        if (!!store) {
            this.store = store;
        }
        else {
            this.store = {
                flags: {},
                version: 0
            };
        }
    }
}

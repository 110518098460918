import {useMemo} from 'react';

import {
  AppPageType,
  AppContentPage,
  AppServicePage,
  getServicePageDefaults,
  AppStructure,
  Applet,
  AppPage,
} from '@medad-sep/core';

import {getMenuItem, MenuItem, PageData} from '../MenuItem';

interface MenuItemsParams {
  appStructure: AppStructure;
  applets: Applet[];
  currentPage?: string;
  isExpanded: boolean;
  onPageSelected: (page: AppPage) => void;
}

type ExtendedPageData = PageData & {
  hideNavigationHeader?: boolean;
  unList?: boolean;
};

export const useMenuItems = ({appStructure, applets, currentPage, isExpanded, onPageSelected}: MenuItemsParams) => {
  const createMenuItem = (pageData: PageData) =>
    getMenuItem({isExpanded, pageData, isActive: currentPage === pageData.id, onPageSelected});

  function getPageData(page: any): ExtendedPageData | undefined {
    if (page.type === AppPageType.CONTENT) {
      const {icon, title, id} = page as AppContentPage;
      return {icon: icon as any, title, id, page};
    } else if (page.type === AppPageType.SERVICE) {
      const service = getServicePageDefaults(page as AppServicePage, applets);
      const {Icon: icon, Suffix: suffix, name: title, hideNavigationHeader = false, id, unList} = service || {};
      return {
        icon: icon as any,
        suffix,
        title,
        id: id || '',
        page,
        hideNavigationHeader,
        unList,
        Wrapper: service?.type === 'inline' ? service?.Component : undefined,
      };
    }
  }

  return useMemo(() => {
    const pagesItems: MenuItem[] = [];

    appStructure?.pages.forEach((page) => {
      const pageData = getPageData(page);
      if (pageData && !pageData.hideNavigationHeader && !pageData.unList) {
        const menuItem = createMenuItem(pageData);
        pagesItems.push(menuItem);
      }
    });

    return pagesItems;
  }, [appStructure, applets, currentPage, isExpanded]);
};

import React, {useEffect, useMemo, useRef} from 'react';

import {ScrollView, XStack, YStack} from '@medad-sep/core';

import {MenuSeperator} from '../../../../Menu/MenuSeperator';

export interface MenuContentProps {
  header?: React.ReactNode;
  content?: React.ReactNode;
  footer?: React.ReactNode;
  scrollOffset?: number;
  onScrollOffsetChange?: (scrollOffset: number) => void;
}

export const MenuContent = ({header, content, footer, scrollOffset, onScrollOffsetChange}: MenuContentProps) => {
  const scrollRef = useRef<ScrollView>();

  useEffect(() => {
    scrollRef?.current?.scrollTo({y: scrollOffset, animated: false});
  }, [scrollOffset, scrollRef.current]);

  const onScroll = useMemo(() => {
    if (!onScrollOffsetChange) return undefined;

    return (event) => onScrollOffsetChange(event.nativeEvent.contentOffset.y);
  }, [onScrollOffsetChange]);

  return (
    <>
      <XStack height={55} flexShrink={0} alignItems="center" justifyContent="flex-start" padding="$4">
        {header}
      </XStack>

      <ScrollView overflow="scroll" flex={1} ref={scrollRef as any} onScroll={onScroll} scrollEventThrottle={16}>
        <YStack marginBottom="$1.2" paddingHorizontal="$3" gap="$2">
          {content}
        </YStack>
      </ScrollView>

      <MenuSeperator />

      <YStack alignItems="center" gap="$2" paddingHorizontal="$3">
        {footer}
      </YStack>
    </>
  );
};

import React, {useState} from 'react';

import {useTranslation} from 'react-i18next';
import {Platform} from 'react-native';
import RNRestart from 'react-native-restart';

import {
  shellScopedKey,
  useUserLangSelector,
  Modal,
  coreScopedKey,
  getLocalizedText,
  Popover,
  ListItem,
  LocalizedText,
  ShellTrans,
  Pressable,
} from '@medad-sep/core';

import {MenuIcon} from '../../../components/Menu/MenuIcon';
import {MenuItem} from '../../../components/Menu/MenuItem';

export const LanguageSelectorBase = ({children}): JSX.Element => {
  const {t, i18n} = useTranslation();
  const {languagesList, onLangChange} = useUserLangSelector();
  const [isRestartConfirmationVisable, setIsRestartConfirmationVisable] = useState(false);

  const trigger = Platform.OS === 'web' ? children : <Pressable>{children}</Pressable>;

  const onRestartLater = () => {
    setIsRestartConfirmationVisable(false);
    RNRestart.restart();
  };

  const onRestartConfirmation = () => {
    setIsRestartConfirmationVisable(false);
  };

  const toggleLanguage = async () => {
    const lang = languagesList.filter((l) => l !== i18n.language)[0];
    await onLangChange(lang);
    if (Platform.OS !== 'web') {
      setIsRestartConfirmationVisable(true);
    }
  };

  return (
    <>
      {Platform.OS !== 'web' && (
        <Modal
          title={getLocalizedText({value: shellScopedKey('settings.notice'), t, i18n})}
          show={isRestartConfirmationVisable}
          onAccept={onRestartLater}
          onCancel={onRestartConfirmation}
          content={getLocalizedText({value: coreScopedKey('languageChangeMsg'), t, i18n})}
          confirmText={getLocalizedText({value: shellScopedKey('settings.restartNow'), t, i18n})}
          cancelText={getLocalizedText({value: shellScopedKey('settings.later'), t, i18n})}
        />
      )}

      <Pressable width="100%" onPress={toggleLanguage}>
        {trigger}
      </Pressable>
    </>
  );
};

export const Language = {
  Label: (
    <LanguageSelectorBase>
      <MenuItem label={<ShellTrans i18nKey="settings.language" />} />
    </LanguageSelectorBase>
  ),
  Icon: (
    <LanguageSelectorBase>
      <MenuIcon icon="LanguageSquareIcon" />
    </LanguageSelectorBase>
  ),
};

import React from 'react';

import {Pressable, ShellTrans, Theme, useAppTheme} from '@medad-sep/core';

import {MenuIcon} from '../../../components/Menu/MenuIcon';
import {MenuItem} from '../../../components/Menu/MenuItem';

const DarkModeSelectorIcon = (): JSX.Element => {
  const {theme, setTheme} = useAppTheme();

  return (
    <Pressable onPress={() => setTheme(theme === Theme.light ? Theme.dark : Theme.light)}>
      <MenuIcon icon={theme === Theme.light ? 'Moon02Icon' : 'Sun03Icon'} />
    </Pressable>
  );
};

const DarkModeSelectorLabel = (): JSX.Element => {
  const {theme, setTheme} = useAppTheme();

  return (
    <Pressable width="100%" onPress={() => setTheme(theme === Theme.light ? Theme.dark : Theme.light)}>
      <MenuItem label={<ShellTrans i18nKey="settings.darkMode" />} />
    </Pressable>
  );
};

export const DarkMode = {
  Label: <DarkModeSelectorLabel />,
  Icon: <DarkModeSelectorIcon />,
};

import React from 'react';

import {Pressable, YStack} from '@medad-sep/core';

import {MenuIcon} from '../../../../Menu/MenuIcon';

import {MenuContent, MenuContentProps} from './MenuContent';
import {MenuItem} from './MenuItem';

type Props = MenuContentProps & {
  isExpanded?: boolean;
  toggleSidebar: () => void;
  menuItems: MenuItem[];
};

export const SideBarMainMenu = ({toggleSidebar, menuItems, isExpanded, ...props}: Props) => {
  return (
    <YStack
      paddingVertical={20}
      width={70}
      borderEndWidth={isExpanded ? 1 : 0}
      borderColor="$border-tertiary"
      paddingHorizontal="$1"
      alignItems="center"
      justifyContent="center"
      gap="$2">
      <MenuContent
        {...props}
        header={
          <Pressable onPress={toggleSidebar}>
            <MenuIcon icon="Menu02Icon" />
          </Pressable>
        }
        content={menuItems.map((menuItem) => menuItem.icon)}
      />
    </YStack>
  );
};

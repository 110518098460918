import ReactNativeStore from "./ReactNativeStore";
import { BasicLogger, SafeLogger } from "@featbit/react-client-sdk";
import ReactNativePlatform from "./ReactNativePlatform";
export function buildConfig(config, debug = false) {
    const { options, reactOptions } = config;
    const fallbackLogger = new BasicLogger({
        level: debug ? 'debug' : 'none',
        destination: console.log
    });
    if (options === undefined) {
        throw new Error('options is required');
    }
    const logger = options.logger ? new SafeLogger(options.logger, fallbackLogger) : fallbackLogger;
    let { store } = config.options;
    if (!store) {
        store = new ReactNativeStore(options);
    }
    return Object.assign(Object.assign({}, config), { options: Object.assign(Object.assign({}, options), { logger, store }), platform: new ReactNativePlatform(Object.assign(Object.assign({}, options), { logger })) });
}

import React from 'react';

import {useNavigation} from '@react-navigation/native';
import {Platform} from 'react-native';

import {
  IconView,
  useIsAuthenticated,
  useAuth,
  Avatar,
  calendarService,
  useIsDemoMode,
  Typography,
  YStack,
  ShellTrans,
  ListItem,
  useAppDirection,
  Popover,
  CoreTrans,
  Pressable,
} from '@medad-sep/core';

import {Settings} from '../../../screens/Settings';
import {MenuIcon} from '../../Menu/MenuIcon';
import {MenuItem} from '../../Menu/MenuItem';

export const ProfileOptionsBase = ({children}): JSX.Element => {
  const {direction} = useAppDirection();
  const isDemoMode = useIsDemoMode();
  const isAuthenticated = useIsAuthenticated();
  const navigation = useNavigation();
  const {logout} = useAuth();
  const trigger = Platform.OS === 'web' ? children : <Pressable>{children}</Pressable>;

  const onLogout = async () => {
    if (isAuthenticated) {
      logout();
      navigation.navigate('main' as never);
      calendarService.clearEvents();
    }
  };

  return (
    <Popover triggerProps={{width: '100%'}} contentProps={{padding: 8}} closable closeOnClick trigger={trigger}>
      <YStack justifyContent="space-between">
        <ListItem
          direction={direction}
          onPress={() => {
            navigation.navigate(Settings.id as never);
          }}
          minWidth="$19"
          prefix={<IconView icon="Settings05Icon" size={18} />}
          title={
            <Typography marginHorizontal="$0.5" fontWeight="500">
              <ShellTrans i18nKey="settings.title" />
            </Typography>
          }
        />
        <ListItem
          direction={direction}
          onPress={!isDemoMode ? onLogout : () => {}}
          prefix={<IconView icon="LogoutSquare02Icon" size={18} />}
          minWidth="$19"
          title={
            <Typography marginHorizontal="$0.5" fontWeight="500">
              <ShellTrans i18nKey="settings.logOut" />
            </Typography>
          }
        />
      </YStack>
    </Popover>
  );
};

export const ProfileOptions = {
  Icon: (
    <ProfileOptionsBase>
      <MenuIcon icon={() => <Avatar size="sm" marginHorizontal={1} />} />
    </ProfileOptionsBase>
  ),
  Label: (
    <ProfileOptionsBase>
      <MenuItem label={<CoreTrans i18nKey="profile" />} />
    </ProfileOptionsBase>
  ),
};

import React from 'react';

import {IconType, IconView, Typography, XStack} from '@medad-sep/core';

interface Props {
  isActive?: boolean;
  label?: React.ReactNode;
  icon?: IconType;
  suffix?: IconType;
}

export const MenuItem = ({isActive, label, icon, suffix}: Props) => {
  return (
    <XStack
      backgroundColor={isActive ? '$nav-item-bg-secondary-active' : '$transparent'}
      hoverStyle={!isActive ? {backgroundColor: '$bg-secondary'} : undefined}
      width="100%"
      height={40}
      paddingHorizontal="$4"
      gap="$3"
      alignItems="center"
      borderRadius="$rounded-lg">
      {icon && (
        <IconView
          icon={icon}
          size={21}
          color={isActive ? '$nav-item-text-secondary-active' : '$nav-item-button-primary-icon-fg'}
        />
      )}
      <Typography
        flex={1}
        ellipse
        fontSize="$16"
        color={isActive ? '$nav-item-text-secondary-active' : '$nav-item-text'}
        fontWeight="700"
        marginTop={2}>
        {label}
      </Typography>

      {suffix && (
        <IconView
          icon={suffix}
          size={21}
          color={isActive ? '$nav-item-text-secondary-active' : '$nav-item-button-primary-icon-fg'}
        />
      )}
    </XStack>
  );
};

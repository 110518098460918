import React from 'react';

import {Platform} from 'react-native';

import {XStack, View, useIsAuthenticated, useAuth, Pressable} from '@medad-sep/core';

import {DarkMode} from '../../screens/Settings/sections/DarkMode';
import {Language} from '../../screens/Settings/sections/Language';
import {Logo} from '../Logo';
import {MenuIcon} from '../Menu/MenuIcon';

import {ProfileOptions} from './components/ProfileOptions';

export const MainHeader = (): JSX.Element => {
  const isMobile = Platform.OS !== 'web';

  const isAuthenticated = useIsAuthenticated();
  const {login} = useAuth();

  return (
    <XStack
      backgroundColor="$bg-primary"
      paddingHorizontal="$6"
      paddingVertical="$0"
      marginHorizontal="$1.5"
      paddingTop="$6"
      paddingBottom={isMobile ? '$1' : '$4'}
      $sm={{marginHorizontal: 0, paddingHorizontal: 16, paddingTop: '$1'}}
      justifyContent="space-between"
      alignItems="center"
      height={isMobile ? '$10' : '$8'}>
      <View width="$18" $sm={{width: '$12'}}>
        <Logo />
      </View>
      <XStack alignItems="center" gap="$1.5">
        <View>{DarkMode.Icon}</View>
        <View>{Language.Icon}</View>
        {isAuthenticated ? (
          <View marginHorizontal="$1">{ProfileOptions.Icon}</View>
        ) : (
          <Pressable onPress={() => login()}>
            <MenuIcon icon="LoginSquare02Icon" />
          </Pressable>
        )}
      </XStack>
    </XStack>
  );
};

import React, {useMemo} from 'react';

import {CoreTrans, Pressable, useAuth, useIsAuthenticated} from '@medad-sep/core';

import {DarkMode} from '../../../../../../screens/Settings/sections/DarkMode';
import {Language} from '../../../../../../screens/Settings/sections/Language';
import {ProfileOptions} from '../../../../../MainHeader/components/ProfileOptions';
import {MenuIcon} from '../../../../../Menu/MenuIcon';
import {MenuItem} from '../../../../../Menu/MenuItem';

export const useFooterItems = () => {
  const isAuthenticated = useIsAuthenticated();
  const {login} = useAuth();

  return useMemo(
    () => ({
      main: (
        <>
          {Language.Icon}
          {DarkMode.Icon}

          {isAuthenticated ? (
            ProfileOptions.Icon
          ) : (
            <Pressable onPress={() => login()}>
              <MenuIcon icon="LoginSquare02Icon" />
            </Pressable>
          )}
        </>
      ),
      sec: (
        <>
          {Language.Label}
          {DarkMode.Label}

          {isAuthenticated ? (
            ProfileOptions.Label
          ) : (
            <Pressable width="100%" onPress={() => login()}>
              <MenuItem label={<CoreTrans i18nKey="login" />} />
            </Pressable>
          )}
        </>
      ),
    }),
    [isAuthenticated, login],
  );
};

import React from 'react';

import {
  AppScreen,
  PageContainer,
  ShellTrans,
  useIsAuthenticated,
  useAppStructure,
  LocalizedText,
  Typography,
} from '@medad-sep/core';

import {SettingsItem} from '../../components/SettingsItem';
import {useAppSettings} from '../../hooks/useAppSettings';

import {SettingSection} from './components/SettingSection';
import {UserProfileSection} from './components/UserProfileSection';

export const SettingsMain = (): JSX.Element => {
  const isAuthenticated = useIsAuthenticated();
  const appStructure = useAppStructure();
  const settingsList = useAppSettings();

  return (
    <AppScreen>
      <UserProfileSection />
      <PageContainer scrollable={false} styles={{gap: 32}}>
        <Typography variant="h4" fontWeight="700">
          <ShellTrans i18nKey="settings.title" />
        </Typography>

        {Object.keys(settingsList).map((category, idx) => {
          const categoryItems = settingsList[category];
          const displayedCategoryItems = categoryItems.filter(({public: isPublic}) => isPublic || isAuthenticated);

          if (!isAuthenticated && !displayedCategoryItems.length) return null;

          return (
            <SettingSection
              key={idx}
              title={<ShellTrans i18nKey={`settings.category.${category}`} />}
              content={displayedCategoryItems.map(({id, Icon, name}) => (
                <SettingsItem key={id} route={id} icon={Icon} label={name} />
              ))}
            />
          );
        })}

        {appStructure.auxiliaryPages.map((group) => {
          if (!group.pages.length) return null;

          return (
            <SettingSection
              key={group.id}
              title={<LocalizedText value={group.title} />}
              content={group.pages?.map(({id, icon, title}) => (
                <SettingsItem key={id} route={id} icon={icon} label={title} />
              ))}
            />
          );
        })}
      </PageContainer>
    </AppScreen>
  );
};

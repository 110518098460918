import React from 'react';

import {useNavigation} from '@react-navigation/native';

import {AppletService, LocalizedText, MiniScreen, Pressable} from '@medad-sep/core';

import {MenuItem} from '../Menu/MenuItem';
import {MenuSeperator} from '../Menu/MenuSeperator';

type Props = {
  service: AppletService;
  onServiceSelected?: (page: AppletService) => void;
  currentPage?: string | undefined;
};

export const GroupServiceMenu = ({service, onServiceSelected, currentPage}: Props) => {
  const navigation = useNavigation();

  const selectService = (service: AppletService) => {
    if (onServiceSelected) {
      onServiceSelected(service);
      return;
    }

    if (service.type === undefined || service.type === 'full') {
      navigation.navigate(service.id as never);
    }
  };

  const ExtraContent = service.Component;

  return (
    <MiniScreen title={<LocalizedText value={service.name} />}>
      {service?.subServices?.map((groups, i) => {
        return (
          <>
            {i !== 0 && <MenuSeperator />}
            {groups?.map((s) => {
              const service = s as AppletService;

              const item = (
                <MenuItem
                  isActive={service.id === currentPage}
                  icon={service.Icon}
                  suffix={service.Suffix}
                  label={<LocalizedText value={service.name} />}
                />
              );

              if (service.type === 'inline') {
                return service.Component?.({children: item});
              }

              return (
                <Pressable key={service.id} onPress={() => selectService(service)}>
                  {item}
                </Pressable>
              );
            })}
          </>
        );
      })}

      {ExtraContent && (
        <>
          <MenuSeperator />
          <ExtraContent />
        </>
      )}
    </MiniScreen>
  );
};

import React from 'react';

import {View} from '@medad-sep/core';

import {Logo} from '../../../../Logo';

import {MenuContentProps} from './MenuContent';
import {MenuItem} from './MenuItem';
import {SecondaryMenuContainer} from './SecondaryMenuContainer';

type Props = MenuContentProps & {
  isExpanded?: boolean;
  menuItems: MenuItem[];
};

export const SideBarSecondaryMenu = ({isExpanded, menuItems, content, ...props}: Props) => {
  return (
    <SecondaryMenuContainer
      isExpanded={isExpanded}
      header={
        <View width="$18" $sm={{width: '$12'}}>
          <Logo />
        </View>
      }
      content={content || menuItems.map((menuItem) => menuItem.label)}
      {...props}
    />
  );
};

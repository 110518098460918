import { name, version } from './version';
import { Platform } from "react-native";
export class ReactNativeInfo {
    get appType() {
        return "React-Native-Client-SDK";
    }
    platformData() {
        var _a;
        return {
            name: 'React Native',
            fbDevice: {
                manufacturer: Platform.select({
                    ios: 'apple',
                    android: Platform.constants.Manufacturer,
                }),
                model: Platform.select({
                    // ios: model n/a from PlatformIOSStatic
                    android: Platform.constants.Model,
                }),
                os: {
                    family: Platform.select({
                        ios: 'apple',
                        default: Platform.OS,
                    }),
                    name: Platform.OS,
                    version: (_a = Platform.Version) === null || _a === void 0 ? void 0 : _a.toString(),
                },
            }
        };
    }
    sdkData() {
        return {
            name: name,
            version: version,
            userAgent: `${this.appType}/${version}`,
        };
    }
}

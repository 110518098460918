import React from 'react';

import {YStack} from '@medad-sep/core';

import {MenuContent} from './MenuContent';

interface Props {
  isExpanded?: boolean;
  header?: React.ReactNode;
  content?: React.ReactNode;
  footer?: React.ReactNode;
}

const ContainerWidth = 280;

export const SecondaryMenuContainer = ({isExpanded, ...props}: Props) => {
  return (
    <YStack
      width={isExpanded ? ContainerWidth : 0}
      style={{
        transition: 'width 0.3s ease, padding 0.3s ease',
      }}>
      <YStack
        flex={1}
        paddingVertical={20}
        justifyContent="space-between"
        width={ContainerWidth}
        paddingHorizontal={isExpanded ? '$1' : 0}
        gap="$2"
        style={{
          transition: 'width 0.3s ease, padding 0.3s ease',
        }}>
        <MenuContent {...props} />
      </YStack>
    </YStack>
  );
};

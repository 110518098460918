import React from 'react';

import {View} from '@medad-sep/core';

export const MenuSeperator = () => {
  return (
    <View
      marginHorizontal="5%"
      marginVertical="$2"
      width="90%"
      flexShrink={0}
      height={1}
      backgroundColor="$border-tertiary"
    />
  );
};

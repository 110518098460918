import React from 'react';

import {Platform} from 'react-native';

import {useAppConfig, Image, useAppTheme} from '@medad-sep/core';

export const Logo = () => {
  const appConfig = useAppConfig();
  const isDarkMode = useAppTheme().theme === 'dark';

  if (!appConfig?.logoUrl) return null;

  return (
    <Image
      key={appConfig.logoUrl}
      source={{uri: appConfig.logoUrl}}
      resizeMode="contain"
      style={{
        height: 64,
        width: 120,
        maxWidth: '70%',
        ...(Platform.OS === 'web' && {
          filter: isDarkMode ? 'brightness(0) invert(1)' : 'unset',
        }),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        tintColor: isDarkMode ? '#fff' : undefined,
      }}
      $gtSm={{height: 60}}
      $gtMd={{height: 50}}
    />
  );
};

import React from 'react';

import {
  LocalizedText,
  LocalizedTextType,
  IconType,
  Pressable,
  XStack,
  AppPage,
  AppletWidgetComponent,
} from '@medad-sep/core';

import {MenuIcon} from '../../../../Menu/MenuIcon';
import {MenuItem} from '../../../../Menu/MenuItem';

export interface PageData {
  id: string;
  page: AppPage;
  title?: LocalizedTextType;
  icon: IconType;
  suffix?: IconType;
  Wrapper?: AppletWidgetComponent;
}

interface Props {
  pageData: PageData;
  isExpanded: boolean;
  isActive?: boolean;
  children?: React.ReactNode;
  onPageSelected: (page: AppPage) => void;
}

export interface MenuItem {
  icon: React.JSX.Element;
  label: React.JSX.Element;
}

const BaseMenuItem = ({pageData, isExpanded, children, onPageSelected}: Props) => {
  const item = (
    <XStack width="100%" alignItems="center" justifyContent={isExpanded ? 'flex-start' : 'center'} flex={1} gap="$2">
      {children}
    </XStack>
  );

  if (pageData.Wrapper) {
    return pageData.Wrapper({children: item});
  }

  return (
    <Pressable flex={1} onPress={() => onPageSelected(pageData.page)}>
      {item}
    </Pressable>
  );
};

export const getMenuItem = ({pageData, isExpanded, isActive, onPageSelected}: Props) => {
  return {
    icon: (
      <BaseMenuItem pageData={pageData} isExpanded={isExpanded} onPageSelected={onPageSelected}>
        <MenuIcon
          isActive={isActive}
          icon={pageData.icon}
          showTooltip={!isExpanded}
          tooltip={<LocalizedText value={pageData.title} />}
          suffix={!isExpanded ? pageData.suffix : undefined}
        />
      </BaseMenuItem>
    ),
    label: (
      <BaseMenuItem pageData={pageData} isExpanded={isExpanded} onPageSelected={onPageSelected}>
        <MenuItem isActive={isActive} label={<LocalizedText value={pageData.title} />} suffix={pageData.suffix} />
      </BaseMenuItem>
    ),
  };
};

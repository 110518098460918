import React from 'react';

import {YStack} from '@medad-sep/core';

import {SideBarMenu} from './SideBarMenu';

export const MainMenu = () => {
  return (
    <YStack backgroundColor="$background" borderColor="transparent">
      <SideBarMenu highlightCurrentPage />
    </YStack>
  );
};

import React, {useEffect, useState} from 'react';

import {useNavigation} from '@react-navigation/native';
import {useControllableState, useEvent} from 'tamagui';

import {
  AppEvents,
  AppletService,
  AppPage,
  AppPageType,
  AppServicePage,
  getServicePageDefaults,
  parseAppletScopedKey,
  useAppEventHub,
  useAppletsList,
  useAppStructure,
  XStack,
} from '@medad-sep/core';

import {GroupServiceMenu} from '../../../../GroupServiceMenu';

import {useFooterItems} from './hooks/useFooterItems';
import {useMenuItems} from './hooks/useMenuItems';
import {SideBarMainMenu} from './SideBarMainMenu';
import {SideBarSecondaryMenu} from './SideBarSecondaryMenu';

interface Props {
  highlightCurrentPage?: boolean;
}

// todo: fix hmr with no full services
export const SideBarMenu = ({highlightCurrentPage}: Props) => {
  const navigation = useNavigation();
  const appStructure = useAppStructure();
  const applets = useAppletsList();
  const appEventHub = useAppEventHub();

  const [currentPage, setCurrentPage] = useState<string>();
  const [currentMiniService, setCurrentMiniService] = useState<AppletService>();
  const [currentGroupService, setCurrentGroupService] = useState<AppletService>();

  const [isExpanded, setIsExpanded] = useControllableState({
    strategy: 'most-recent-wins',
    prop: undefined,
    defaultProp: true,
  });
  const [scrollOffset, setScrollOffset] = useControllableState({
    strategy: 'most-recent-wins',
    prop: undefined,
    defaultProp: 0,
  });

  useEffect(
    (() => {
      if (!highlightCurrentPage) return;
      return appEventHub?.addListener(AppEvents.ROUTE, (args) => {
        setCurrentPage(args);

        try {
          const {appletId} = parseAppletScopedKey(args);
          const service = getServicePageDefaults({appletId, serviceId: args}, applets);
          if (!service || !service.parentServiceId) return;

          const parentService = getServicePageDefaults({appletId, serviceId: service.parentServiceId}, applets);
          if (!parentService) return;
          setCurrentGroupService(parentService);
        } catch {}
      });
    }) as any,
    [],
  );

  const navigateToPage = (id: string, resetNonFullView = true) => {
    navigation.navigate(id as never);

    if (resetNonFullView) {
      setCurrentGroupService(undefined);
      setCurrentMiniService(undefined);
    }
  };

  const findGroupFirstRoute = (service: AppletService) => {
    for (const group of service.subServices || []) {
      for (const subService of group) {
        if (subService.type == undefined || subService.type === 'full') {
          return subService.id;
        }
      }
    }
  };

  const onServiceSelected = (service: AppletService, resetNonFullView = true) => {
    if (!service.type) {
      service.type = 'full';
    }

    switch (service.type) {
      case 'mini':
        if (service.id === currentMiniService?.id) {
          setCurrentMiniService(undefined);
        } else {
          setCurrentMiniService(service);
          setIsExpanded(true);
        }

        break;
      case 'group':
        let route: string | undefined;
        if (service.id !== currentGroupService?.id) {
          route = findGroupFirstRoute(service);
        }

        setCurrentGroupService(service);
        setCurrentMiniService(undefined);

        if (route) navigateToPage(route, false);
        break;
      case 'full':
        navigateToPage(service.id, resetNonFullView);
        break;
    }
  };

  const onPageSelected = (page: AppPage) => {
    if (page.type === AppPageType.CONTENT) {
      navigateToPage(page.id);
      return;
    }

    if (page.type === AppPageType.SERVICE) {
      const service = getServicePageDefaults(page as AppServicePage, applets);
      if (!service) return;

      onServiceSelected(service);
    }
  };

  const menuItems = useMenuItems({
    appStructure,
    applets,
    currentPage: currentMiniService?.id || currentGroupService?.id || currentPage,
    isExpanded,
    onPageSelected,
  });

  const footerItems = useFooterItems();

  const toggleSidebar = useEvent(() => {
    setIsExpanded((prev) => !prev);
  });

  const MiniServiceComponent = currentMiniService?.Component;
  const SecondaryContent = MiniServiceComponent ? (
    <MiniServiceComponent />
  ) : currentGroupService ? (
    <GroupServiceMenu
      currentPage={currentPage}
      service={currentGroupService}
      onServiceSelected={(service) => onServiceSelected(service, false)}
    />
  ) : null;

  return (
    <XStack
      className="no-scrollbar"
      backgroundColor="$bg-primary"
      height="100%"
      borderEndWidth={1}
      borderColor="$border-tertiary">
      <SideBarMainMenu
        isExpanded={isExpanded}
        menuItems={menuItems}
        toggleSidebar={toggleSidebar}
        scrollOffset={scrollOffset}
        onScrollOffsetChange={setScrollOffset}
        footer={footerItems.main}
      />
      <SideBarSecondaryMenu
        isExpanded={isExpanded}
        menuItems={menuItems}
        scrollOffset={scrollOffset}
        onScrollOffsetChange={setScrollOffset}
        content={SecondaryContent}
        footer={footerItems.sec}
      />
    </XStack>
  );
};

import React from 'react';

import {shellScopedKey} from '@medad-sep/core';

import {SettingsItem} from '../../models';

import {ProfileSelector} from './components/ProfileSelector';

const Component = () => {
  return <ProfileSelector />;
};

export const UserProfile: SettingsItem = {
  id: 'user-profile',
  name: shellScopedKey('settings.userProfile.title'),
  Icon: 'Profile02Icon',
  Component,
  public: false,
};

import React from 'react';

import {IconType, IconView, Tooltip, View} from '@medad-sep/core';

interface Props {
  isActive?: boolean;
  tooltip?: React.ReactNode;
  icon?: IconType;
  showTooltip?: boolean;
  suffix?: IconType;
}

export const MenuIcon = ({isActive, icon, showTooltip, tooltip, suffix}: Props) => {
  return (
    <Tooltip hide={!tooltip || !showTooltip} allowFlip placement="left-start" content={tooltip}>
      <View
        key={`${isActive}`}
        position="relative"
        animation="bouncy"
        enterStyle={isActive ? {scale: 0.8, y: 5} : null}
        scale={1}
        y={0}
        backgroundColor={isActive ? '$fg-brand-primary' : '$transparent'}
        hoverStyle={!isActive ? {backgroundColor: '$bg-secondary'} : undefined}
        alignItems="center"
        width={40}
        height={40}
        justifyContent="center"
        borderRadius="$rounded-lg">
        <IconView
          icon={icon || 'File01Icon'}
          size={21}
          color={isActive ? '$nav-item-button-primary-icon-fg-active' : '$nav-item-button-primary-icon-fg'}
        />

        {suffix && (
          <View position="absolute" width="100%" height="100%" alignItems="flex-end" justifyContent="flex-end">
            <View scale={0.5}>
              <IconView
                icon={suffix}
                size={24}
                color={isActive ? '$nav-item-button-primary-icon-fg-active' : '$nav-item-button-primary-icon-fg'}
                variant="solid"
              />
            </View>
          </View>
        )}
      </View>
    </Tooltip>
  );
};
